import {ApiService} from '@/services/ApiService'

const URL = '/api/quotedescription'

class QuotationDescriptionService extends ApiService {
    constructor(){
        super()
    } 
    getUrl(){
        return URL
    }

}

export default new QuotationDescriptionService()